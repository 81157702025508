<template>
    <div class="subscribe">
        <v-row style="max-width: 1400px" class="mx-auto">
            <v-col cols="12">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar height="50" color="purple darken-2 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('subscribe.list_of_subscribes', { p1: points.length, p2: smsCount, p3: priceTotal }) }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex">

                        <v-spacer></v-spacer>

                        <v-text-field style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                    </v-card-title>
                    
                    <v-data-table
                        :mobile-breakpoint="0"
                        dense
                        no-data-text="Нет данных"
                        no-results-text="Нет данных, попробуйте изменить запрос"
                        :footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [25, 50, 100, -1],
                        }"
                        :headers="headers"
                        :search="search"
                        :items="points"
                        item-key="id"
                        :loading="loading"
                        :loading-text="$t('common.loading')"
                        @update:items-per-page="updatePaginate"
                        @update:page="updatePaginate"
                        ref="dTable"
                    >

                        <template v-slot:[`item.packet`]="{ item }">
                            {{ getSubscribeName(item.settings.subscribe_id) }}
                        </template>

                        <template v-slot:[`item.credit_prev`]="{ item }">
                            {{ getSubscribeCredit(item.settings.subscribe_id, item.settings.sms_count_prev) }}
                        </template>

                        <template v-slot:[`item.credit_curr`]="{ item }">
                            {{ getSubscribeCredit(item.settings.subscribe_id, item.settings.sms_count_curr) }}
                        </template>


                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'
    export default {
        data() {
            return {
                config,
                loading: false,
                loadingOrder: false,
                search: '',
                pointPhone: '',
                pointCopy: {},
                unallocatedUsers: {},
                unallocatedUsersCopy: {},
                points: [],
                point_users: [],
                usersCount: 0,
                usersCountOnline: 0,
                pointDialog: false,
                ordersDialog: false,
                dialogHeader: '',
                dialogContent: '',
                headers: [
                    {
                        text: 'Название точки',
                        align: ' th-first-item',
                        value: 'name',
                    },
                    {
                        text: 'Пакет',
                        value: 'packet',
                    },
                    {
                        text: 'Расход пред, шт.',
                        value: 'settings.sms_count_prev',
                    },
                    {
                        text: 'Кредит пред, шт.',
                        value: 'credit_prev',
                    },
                    {
                        text: 'Сумма пред, руб.',
                        value: 'settings.subscribe_cost_prev',
                    },
                    {
                        text: 'Расход текущ, шт.',
                        value: 'settings.sms_count_curr',
                    },
                    {
                        text: 'Кредит текущ, шт.',
                        value: 'credit_curr',
                    },
                    {
                        text: 'Сумма текущ, руб.',
                        value: 'settings.subscribe_cost_curr',
                    },
                ],
                editFlag: false,
                addressUpdateFlag: false,
                addressResult: '',
                addressColor: '',
                timerAddressUpdate: null,
                createFlag: false,
                detailsPoint: 0,
                smsCount: 0,
                priceTotal: 0,
                detailsDate1: '',
                detailsDate2: '',
                orders: [],
                settings: null,
            }
        },
        components: {
        },
        mounted() {
            console.log('start!')

            console.log('this.$store.state.user.role', this.$store.state.user.role)
            console.log('config.user.role.operator', config.user.role.operator)

            if (this.$store.state.user.role == config.user.role.operator) {
                this.$store
                    .dispatch('settings_get')
                    .then(res => {
                        if (res.data.success) {
                            this.settings = res.data.settings
                            this.updateData()
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })

                // this.settings = this.$store.state.settings
                // console.log('settings', this.settings)
                // this.updateData()

            } else {

                this.$store
                    .dispatch('user_operator_get')
                    .then(res => {
                        if (res.data.success) {

                            this.settings = res.data.user.settings
                            console.log('settings', this.settings)
                            this.updateData()

                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }

        },

        beforeDestroy() {},

        destroyed() {},
        watch: {},
        computed: {},
        methods: {
            
            updatePaginate() {

            },

            getSubscribeName(subscribe_id) {
                const subscribe = this.settings.subscribes.find(s => s.id == subscribe_id)
                return subscribe.name + ' - ' + subscribe.count
            },

            getSubscribeCredit(subscribe_id, count) {
                let res = count - this.settings.subscribes.find(s => s.id == subscribe_id).count
                console.log('count', count)
                if (!res || res < 0) {
                    res = 0
                }
                return res
            },
            
            onClickUpdate() {
                this.updateData()
            },
            sortPoints(a, b) {
                if (a.name > b.name) {
                    return 1
                }
                if (a.name < b.name) {
                    return -1
                }
                if (a.address > b.address) {
                    return 1
                }
                if (a.address < b.address) {
                    return -1
                }
                return 0
            },
            updateData() {
                this.loading = true
                if (this.settings && this.settings.subscribe_enable) {
                    if (this.$store.state.user.role == config.user.role.operator) {
                        this.$store
                            .dispatch('points_get')
                            .then(res => {
                                this.points = []

                                if (res.data.success) {

                                    this.points = res.data.points.filter(p => p.settings.subscribe_enable && p.settings.subscribe_id).sort(this.sortPoints)
                                    
                                    this.smsCount = 0
                                    this.priceTotal = 0

                                    console.log('points', this.points)
                                    for (const point of this.points) {
                                        this.smsCount += point.settings.sms_count_curr
                                        this.priceTotal += point.settings.subscribe_cost_curr
                                    }
                                    this.loading = false
                                }
                                if (!this.smsCount) {
                                    this.smsCount = 0
                                }

                            })
                            .catch(err => {
                                this.loading = false
                                console.log(err)
                            })
                    } else {
                        this.$store
                            .dispatch('point_get', this.$store.state.user.point_id)
                            .then(res => {
                                this.points = []

                                if (res.data.success) {
                                    const point = res.data.point
                                    console.log('point', point)

                                    if (point.settings.subscribe_enable && point.settings.subscribe_id) {

                                        this.points.push(point)

                                        this.smsCount = point.settings.sms_count_curr
                                        this.priceTotal = point.settings.subscribe_cost_curr
                                    }
                                    this.loading = false
                                }
                                if (!this.smsCount) {
                                    this.smsCount = 0
                                }

                            })
                            .catch(err => {
                                this.loading = false
                                console.log(err)
                            })
                    }
                } else {
                    this.loading = false
                    console.log('нет настроек - считаем, что подписки отключены')
                }
            },
        },
    }
</script>

<style lang="stylus">

    .subscribe
        height 100%
        padding-top 10px !important

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px
                    white-space nowrap
            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                .v-data-table__expanded__row:hover
                    background #e4e4e4 !important
                    cursor pointer !important

                td
                    white-space nowrap
                    font-size 0.75rem
                    padding 0 8px
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px

        .expanded-content
            box-shadow inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75)
            background none !important
            &:hover
                cursor default !important

        .th-first-item
            min-width 20%

        b
            font-weight 500

    .subscribe-dialog
        b
            font-weight 500
        .v-list
            overflow-y auto
            height 132px
            .list-group
                height 100%
                .v-list-item
                    cursor pointer
                    &:hover
                        background-color beige
                .sortable-ghost
                    background-color beige


</style>
